import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import possibleTypes from '@client/graphql/__generated__/possible-types';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache({
  possibleTypes: possibleTypes.possibleTypes,
});

const MUTE_ERROR_TOAST_CONTEXT_KEY = 'skipGlobalErrorHandler';
export const MUTE_ERROR_TOAST_QUERY_OPTIONS = {
  context: {
    [MUTE_ERROR_TOAST_CONTEXT_KEY]: true,
  },
};

export function createApolloClient(options: {
  clientOptions: Partial<
    ApolloClientOptions<InMemoryCache> & {
      onError: (error: ErrorResponse) => void;
    }
  >;
  authorization?: {
    credentials?: string;
    setHeaders: (headers: Record<string, string>) => Record<string, string>;
  };
}) {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(
      ({ headers }: { headers: Record<string, string> }) =>
        options.authorization?.setHeaders(headers)
    );

    return forward(operation);
  });

  const { credentials, uri, ...clientOptions } = options.clientOptions;

  const uploadLink = createUploadLink({
    uri,
    credentials: credentials || 'same-origin',
    fetch(uri: string, options) {
      if (
        process.env.NODE_ENV === 'test' ||
        window.ENVS.environment === 'e2e'
      ) {
        if (typeof options?.body === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { operationName } = JSON.parse(options.body);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          uri += `?operation=${operationName}`;
        } else if (options?.body instanceof FormData) {
          const operations = options?.body.get('operations') as string;
          if (operations) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { operationName }: { operationName: string } =
              JSON.parse(operations);
            uri += `?operation=${operationName}`;
          }
        }
      }

      return fetch(uri, options);
    },
  });

  const errorLink = onError((error) => {
    const { graphQLErrors, networkError, operation } = error;

    // NOTE: Check if the operation has its own error handler
    //       One good use case is when we want to mute the error toast
    const context = operation.getContext();
    const hasOwnErrorHandler = context.hasOwnProperty(
      MUTE_ERROR_TOAST_CONTEXT_KEY
    );
    if (hasOwnErrorHandler) {
      return;
    }

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) =>
        // eslint-disable-next-line no-console
        console.error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${JSON.stringify(
            extensions
          )}`
        )
      );
    }
    if (networkError) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-console
      console.error(`[Network error]: ${networkError}`);
    }

    options?.clientOptions?.onError && options.clientOptions?.onError(error);
  });

  const client = new ApolloClient({
    ...clientOptions,
    cache:
      process.env.NODE_ENV === 'test'
        ? new InMemoryCache({
            possibleTypes: possibleTypes.possibleTypes,
          })
        : cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    name: clientOptions.name,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    link: ApolloLink.from([errorLink, authLink, uploadLink]),
  });

  return client;
}
