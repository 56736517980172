
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ContingencyResult": [
      "Contingency",
      "ContingencyError"
    ],
    "KeyTermResult": [
      "KeyTerm",
      "KeyTermError"
    ],
    "MarketDataSource": [
      "CompsSource",
      "IndigoOffersSource"
    ]
  }
};
      export default result;
    