/**
 * Indigo colors design system.
 *
 * From Figma: https://www.figma.com/design/qt4SZGQsiEQ96fb7GLaliM/Indigo?node-id=0%3A1&t=pGaHrxscDeeKdBbN-1
 */
export const indigoColors = {
  indigo: {
    700: '#151532',
    800: '#0C0C2A',
    900: '#090B1E',
  },
  cyan: {
    50: '#F4F9FF',
    100: '#E8F3FF',
    200: '#CFE7FF',
    300: '#B3DAFF',
    400: '#91CDFF',
    500: '#64BEFF',
    600: '#59AAE4',
    700: '#4D93C6',
    800: '#3F78A1',
    900: '#2D5572',
    gradient: {
      start: '#0066B0',
      end: '#9FD7FF',
    },
  },
  purple: {
    50: '#F5F4FC',
    100: '#EAE9F8',
    200: '#D3D2F1',
    300: '#B9B7EA',
    400: '#9B97E3',
    500: '#756FDB',
    600: '#756FDB',
    700: '#5B56AA',
    800: '#4A468B',
    900: '#343262',
    gradient: {
      start: '#6E67D6',
      end: '#B973FF',
    },
  },
  green: {
    50: '#F3FAF6',
    100: '#E6F5EC',
    200: '#CBEAD8',
    300: '#AADFC1',
    400: '#83D3A7',
    500: '#47C689',
    600: '#40B17B',
    700: '#37996A',
    800: '#2D7D57',
    900: '#20593D',
  },
  red: {
    50: '#FFF3F4',
    100: '#FFE7E8',
    200: '#FFCCCE',
    300: '#FFACB1',
    400: '#FF868D',
    500: '#FF4F5D',
    600: '#E44753',
    700: '#C63D48',
    800: '#A1323B',
    900: '#72232A',
  },
  yellow: {
    50: '#FFFAF2',
    100: '#FFF5E4',
    200: '#FFECC6',
    300: '#FFE1A1',
    400: '#FFD672',
    500: '#FFCB00',
    600: '#E4B600',
    700: '#C69D00',
    800: '#A18000',
    900: '#725B00',
  },
  black: '#000000',
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  white: '#FFFFFF',
  whiteAlpha: {
    25: 'rgba(255, 255, 255, 0.02)',
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  tan: {
    300: '#c8c6be',
  },
  // Converted background colors and transparent color overlays into opaque hex values
  //    Needed for certain styles that have to overlap a different background
  //    Should be bg (opaque) color + transparent overlay for naming consistency
  opaque: {
    indigo_700_whiteAlpha_100: '#151532', // indigo.700 + whiteAlpha.100
    indigo_800_whiteAlpha_100: '#161633', // indigo.800 (bg.main) + whiteAlpha.100
  },
};
